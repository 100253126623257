<template>
	<div class="fixed top-0 py-9 bg-[#9090901F] backdrop-blur-2xl z-50 w-full">
		<div class="mx-auto container px-4 md:px-24 flex items-center justify-between">
			<div class="flex gap-20">
				<NuxtImg
					class="h-10 w-auto"
					src="/images/logo.svg"
					alt="RealityNode - The fastest and most reliable private RPC."
				/>
				<ul class="hidden md:flex flex gap-8 items-center">
					<li v-for="item of menuItems" :key="item.link">
						<button
							@click="onHeaderClick(item.link)"
							class="text-white font-extrabold hover:text-brand-primary transition-colors duration-300"
							:title="item.name"
						>
							{{ item.name }}
						</button>
					</li>
				</ul>
			</div>
			<ConnectWallet />
		</div>
	</div>
	<div
		id="featured"
		class="min-h-screen flex items-center justify-center bg-cover bg-no-repeat"
		style="background-image: url('/images/featured-bg.jpg')"
	>
		<div class="container relative px-4 md:px-24">
			<div class="space-y-8 md:max-w-[570px] rounded-2xl">
				<h1 class="text-white font-bold text-4xl md:text-6xl md:leading-[72px]">
					Optimize your experience with Reality Node.
				</h1>
				<h2 class="text-white text-xl text-white/[0.9]">
					High-performance Solana RPC, designed for speed, stability, and profitability.
				</h2>
				<Button href="https://discord.gg/aHyw23HRC2">Start now</Button>
			</div>
			<NuxtImg
				class="hidden md:block absolute right-0 top-1/2 transform -translate-y-1/2 h-[548px] w-auto"
				src="/images/cheetah.png"
				alt="RealityNode - The fastest and most reliable private RPC"
			/>
		</div>
	</div>
	<div class="bg-black pt-16 md:pt-32 px-4 md:px-0" id="technologies">
		<div class="max-w-[656px] mx-auto">
			<h2 class="text-white font-bold text-5xl text-center mb-16 md:mb-8 md:leading-[72px]">
				Driven by the best technologies.
			</h2>
			<div class="flex gap-8 items-center flex-col md:flex-row">
				<div v-for="technology of technologies" :key="technology.name">
					<NuxtImg class="h-8 md:h-16 w-auto" :src="technology.image" :alt="technology.name" />
				</div>
			</div>
		</div>
	</div>
	<div class="py-16 md:py-32 bg-black" id="service">
		<div class="container mx-auto px-4 md:px-24">
			<div class="grid gap-8 mb-12 md:grid-cols-2">
				<div>
					<h2 class="text-white font-bold text-5xl mt-8 mb-12 md:leading-[72px] text-center md:text-left">
						Power up with ultra-fast speed.
					</h2>
					<div
						class="space-y-4 p-8 bg-no-repeat"
						style="background-image: url('/images/highlight-box-bg.png'); background-size: 100% 100%"
					>
						<h3 class="text-white font-semibold text-base">Tested and trusted by the best.</h3>
						<p class="text-white/[0.8] text-base">
							We tested and gathered feedback from advanced users and well-known whales in the community,
							delivering significant improvements and proving our commitment to openness and progress.
							Your opinion matters!
						</p>
						<span
							class="bg-[#FFFFFF0D] border border-[#FFFFFF0D] rounded py-2 px-4 flex w-fit gap-2.5 text-white/[0.5] text-sm flex items-center"
							><NuxtImg class="h-4 w-4" src="/images/verify-icon.svg" />Quality assured.</span
						>
					</div>
				</div>
				<NuxtImg class="w-full h-auto ml-auto" src="/images/server.svg" alt="Power up with ultra-fast speed." />
			</div>
			<div class="grid gap-8 sm:grid-cols-1 md:grid-cols-3">
				<div class="bg-admin-gray rounded-2xl py-8 px-6" v-for="service of services" :key="service.name">
					<NuxtImg class="mb-8 h-12 w-12" :src="service.icon" :alt="service.name" />
					<h3 class="mb-4 text-lg font-semibold">{{ service.name }}</h3>
					<p class="text-sm text-white/[0.8]">{{ service.description }}</p>
				</div>
			</div>
			<Button class="mx-auto mt-16 md:mt-32" href="https://discord.gg/aHyw23HRC2">Get started now</Button>
		</div>
	</div>
	<footer>
		<div class="px-4 md:px-24 mx-auto container py-8 md:py-16 flex flex-col md:flex-row justify-between">
			<div>
				<div class="text-white/[0.9] text-sm space-y-2">
					<p>Speed, precision, stability, and security.</p>
					<p>Reality Node, your private RPC.</p>
				</div>
				<div class="flex gap-4 mt-4 justify-center md:justify-start">
					<NuxtLink
						v-for="social of socials"
						:key="social.link"
						class="bg-brand-secondary h-12 w-12 rounded-xl flex items-center justify-center text-2xl text-white hover:bg-brand-primary transition-colors duration-300"
						:to="social.link"
						target="_blank"
					>
						<i :class="social.icon"></i>
					</NuxtLink>
				</div>
			</div>
			<NuxtImg
				class="mt-8 md:mt-0 h-12 w-auto"
				src="/images/spaceshipws.svg"
				alt="Powered by Spaceship Web Solutions."
			/>
		</div>
	</footer>
</template>
<script setup lang="ts">
const menuItems = [
	{
		name: 'Home',
		link: 'featured',
	},
	{
		name: 'Technologies',
		link: 'technologies',
	},
	{
		name: 'Our service',
		link: 'service',
	},
]

const technologies = [
	{
		name: 'Jito Labs',
		image: '/images/jito-labs.svg',
	},
	{
		name: 'Triton',
		image: '/images/triton-yellowstone.svg',
	},
	{
		name: 'Solana',
		image: '/images/solana.svg',
	},
	{
		name: 'Jupiter',
		image: '/images/jupiter.svg',
	},
]

const services = [
	{
		icon: '/images/server-icon.svg',
		name: 'Servers',
		description:
			'Located in Ashburn, VA, we deliver unmatched quality with high speed and low latency, ensuring fast transactions and smooth readings.',
	},
	{
		icon: '/images/solana-icon.svg',
		name: 'Unmatched expertise',
		description:
			'With over 8 years of experience, we’ve optimized a RPC with no computing unit limits, dedicated servers for a few users, low ping, and 750 TPS.',
	},
	{
		icon: '/images/support-icon.svg',
		name: 'Support',
		description:
			'24/7 dedicated support handled directly by the owners. Any issue you encounter will be resolved in no time.',
	},
]

const socials = [
	{
		icon: 'fi fi-brands-discord',
		link: 'https://discord.gg/aHyw23HRC2',
	},
	{
		icon: 'fi fi-brands-twitter-alt',
		link: 'https://x.com/realitynode',
	},
]

const onHeaderClick = (container: string) => {
	const element = document.getElementById(container)

	if (element) {
		element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' })

		window.history.pushState(null, '', `/#${container}`)
	}
}

useSeoMeta({
	title: 'RealityNode - Optimize your experience with Reality Node.',
	ogTitle: 'RealityNode - Optimize your experience with Reality Node.',
	description: 'High-performance Solana RPC, designed for speed, stability, and profitability.',
	ogDescription: 'High-performance Solana RPC, designed for speed, stability, and profitability.',
	ogImage: 'https://realitynode.xyz/images/og-image.png',
	twitterCard: 'summary_large_image',
})

definePageMeta({
	middleware: ['authenticate-user'],
})
</script>
