<template>
	<NuxtLink v-if="href" :to="href" :class="mergedClass" :target="openNewWindow ? `_blank` : '_self'" v-bind="attrs">
		<slot></slot>
	</NuxtLink>
	<button v-else :class="mergedClass" v-bind="attrs" :type="type">
		<slot></slot>
	</button>
</template>
<script setup lang="ts">
import { twMerge } from 'tailwind-merge'
import type { ButtonHTMLAttributes } from 'vue'

const props = defineProps<{
	variant?: 'primary'
	href?: string
	isLoading?: boolean
}>()

const attrs = useAttrs()

defineOptions({
	inheritAttrs: false,
})

const type = (attrs.type as ButtonHTMLAttributes['type']) || 'button'
const mergedClass = computed(() =>
	twMerge(
		'px-6 py-2 md:py-3 bg-white text-black text-lg font-bold rounded-full md:px-20 transition-colors duration-300 hover:bg-brand-primary flex w-fit items-center justify-center',
		attrs.class as string
	)
)

const openNewWindow = computed(() => (props.href as string)?.startsWith('https://'))
</script>
