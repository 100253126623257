<template>
	<div>
		<AdminButton v-if="user" href="/dashboard" variant="secondary" class="rounded-full px-6 md:px-20"
			><i class="fi fi-br-sign-in-alt"></i>Access Dashboard</AdminButton
		>
		<Button v-if="!user" @click="isModalOpen = true">Connect wallet</Button>
	</div>
	<SupportedWalletsModal v-if="isModalOpen" @close="isModalOpen = false" />
</template>
<script setup lang="ts">
const { user } = useUserStore()
const isModalOpen = ref(false)
</script>
