<template>
	<AdminModal @close="$emit('close')">
		<div class="space-y-6">
			<NuxtImg src="/images/logo.svg" alt="Reality Node" class="h-10 w-auto mx-auto" />
			<ul class="space-y-6">
				<li v-for="wallet of $supportedWallets" :key="wallet.name">
					<AdminButton
						class="w-full"
						@click="() => onSelectWallet(wallet)"
						variant="secondary"
						:isLoading="loadings[wallet.name]"
					>
						<!-- :src="`images/${wallet.name.toLowerCase().split(' ')[0]}.png`" -->
						<NuxtImg class="h-4 w-4 flex" :src="wallet.icon" :alt="wallet.name" />
						{{ wallet.name }} Wallet
					</AdminButton>
				</li>
			</ul>
			<p class="text-sm text-center">
				<b>Connect your crypto wallet</b> have access ot all the functionalities of our node.
			</p>
		</div>
	</AdminModal>
</template>
<script setup lang="ts">
import { BaseMessageSignerWalletAdapter } from '@solana/wallet-adapter-base'
import naclUtil from 'tweetnacl-util'
import bs58 from 'bs58'

const { $supportedWallets, $api, $toast } = useNuxtApp()
const { connectWallet } = useWalletStore()
const { login } = useUserStore()
const emit = defineEmits(['close'])
const router = useRouter()

const loadings = ref<Record<string, boolean>>({})

const onSelectWallet = async (wallet: BaseMessageSignerWalletAdapter) => {
	loadings.value[wallet.name] = true

	try {
		await connectWallet(wallet)

		if (!wallet.publicKey) return

		const { message } = await $api<any>(`/auth/${wallet.publicKey.toString()}/get-nonce`)
		const signature = await wallet.signMessage(naclUtil.decodeUTF8(message))

		const { user } = await $api<any>('/auth/login', {
			method: 'POST',
			body: JSON.stringify({
				walletAddress: wallet.publicKey.toString(),
				signature: bs58.encode(signature),
				walletName: wallet.name.toLowerCase(),
			}),
		})

		login(user)

		await router.push('/dashboard')

		emit('close')
	} catch (error) {
		console.error(error)

		$toast.error('An error occurred while connecting the wallet')
	}

	loadings.value[wallet.name] = false
}
</script>
